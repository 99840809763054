<template>
  <div v-if="info" class="invite dfc">
    <!-- 会议发起人title -->
    <div class="inator-box dfr cross-center">
      <div class="inator-img dfr">
        <img src="../assets/images/detail-brief.png" alt="" class="w100 h100" />
      </div>
      <div class="fs32 fw500 fc333">会议发起人</div>
    </div>
    <!-- 会议发起人 -->
    <div class="head-box">
      <!-- 头像box -->
      <div v-if="info" class="head dfr">
        <div class="portrait-box" v-if="info.user">
          <img
            v-if="!info.user.avatar"
            src="../assets/images/default-portrait.png"
            alt=""
            class="w100 h100"
          />
          <img
            v-else
            :src="info.user.avatar"
            alt=""
            class="w100 h100 object-cover"
          />
          <div class="badge-box" v-if="info.user.is_major_auth">
            <img
              src="../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- message -->
        <div class="message-box dfc flex1">
          <div
            v-if="info.user && info.user.name"
            class="fs32 fw600 fc333 dfr cross-center"
          >
            {{ info.user.name }}
            <div v-if="info.user.job" class="my-job fs24 fw400 fc999">
              {{ info.user.job }}
            </div>
          </div>
          <div v-if="info.user.company" class="company fs24 fw400 fc666">
            {{ info.user.company }}
          </div>
          <div
            v-if="info.user.industry || info.user.scale"
            class="scale fs24 fw400 fcb2"
          >
            <span v-if="info.user.industry">{{ info.user.industry.name }}</span>
            <span v-if="info.user.industry && info.user.scale">.</span>
            <span v-if="info.user.scale">{{ info.user.scale.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 会议主持人title -->
    <div class="host-box dfr cross-center">
      <div class="inator-img dfr">
        <img src="../assets/images/detail-style.png" alt="" class="w100 h100" />
      </div>
      <div class="fs32 fw500 fc333">会议主持人</div>
    </div>
    <!-- 会议主持人 -->
    <div class="head-box dfc">
      <div class="head dfr">
        <!-- 头像box -->
        <div class="portrait-box">
          <!-- v-if="info.host_user.avatar == ''" -->
          <img
            v-if="info.host_user.avatar"
            :src="info.host_user.avatar"
            alt=""
            class="w100 h100 object-cover"
          /><img
            v-else
            src="../assets/images/default-portrait.png"
            alt=""
            class="w100 h100 object-cover"
          />
          <div v-if="info.host_user.is_auth" class="badge-box">
            <img
              src="../assets/images/home-emblem.png"
              alt=""
              class="w100 h100"
            />
          </div>
        </div>
        <!-- message -->
        <div class="message-box dfc flex1">
          <div class="fs32 fw600 fc333 dfr cross-center" v-if="info.host_user">
            {{ info.host_user.name }}
            <div class="my-job fs24 fw400 fc999">
              <name-plate
                :nameplate="info.host_user.host_user_info.level.name"
              />
            </div>
          </div>
          <div class="company fs24 dfr cross-center">
            <div class="fw400 fc666">主持场数</div>
            <div class="company-number fw600 fctheme">
              {{
                parseFloat(info.host_user.host_user_info.base_count) ||
                  0 + parseFloat(info.host_user.host_user_info.real_count) ||
                  0
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- 领域 -->
      <div class="field-list-box dfr flex-wrap">
        <div class="field-list fs20 fw400 fc666 dfr flex-center">企业管理</div>
      </div>
    </div>

    <!-- 会议时间title -->
    <div class="host-box dfr cross-center">
      <div class="inator-img dfr">
        <img src="../assets/images/meeting-time.png" alt="" class="w100 h100" />
      </div>
      <div class="fs32 fw500 fc333">会议时间</div>
    </div>
    <!-- 会议时间 -->
    <div class="meeting-time fs28 fw400 fc666 dfr flex-warp">
      {{ info.hold_datetime }}
    </div>

    <!-- 会议形式 -->
    <div class="host-box dfr cross-center">
      <div class="inator-img dfr">
        <img src="../assets/images/invite-line.png" alt="" class="w100 h100" />
      </div>
      <!-- is_online 1:线下 2:线上 -->
      <div class="fs32 fw500 fc333" v-if="info.is_online <= 1">线下会议</div>
      <div class="fs32 fw500 fc333" v-else-if="info.is_online == 2">
        线上会议
      </div>
    </div>
    <div
      class="dfr main-between cross-center mt20 pr36"
      style="margin-left:0.88rem"
    >
      <!-- is_online 1:线下 2:线上  0 旧会议 默认线下-->
      <div
        class="fs28 fc666"
        style="width:5.78rem; user-select: text;"
        v-if="info.is_online == 2"
      >
        腾讯会议房间号 {{ info.room_no }}
      </div>
      <div
        class="fs28 fc666"
        style="width:5.78rem"
        @click="showMap = true"
        v-else-if="info.is_online == 1"
      >
        <!-- meeting_space_type 1:使用平台推荐的场所 2:使用自己的场所 -->
        <div v-if="info.meeting_space_type == 1">
          {{ info.meeting_space.address }}
        </div>
        <div v-else>
          {{ info.userself_address }}
        </div>
      </div>
      <div
        class="fs28 fc666"
        style="width:5.78rem; user-select: text;"
        v-if="info.is_online == 0"
      >
        自有场所 私董会专用会议室
      </div>
      <van-icon v-if="info.is_online == 1" name="arrow" color="#CDCDCD" />
    </div>

    <!-- 所属领域title -->
    <div class="host-box dfr cross-center">
      <div class="inator-img dfr">
        <img
          src="../assets/images/meeting-field.png"
          alt=""
          class="w100 h100"
        />
      </div>
      <div class="fs32 fw500 fc333">所属领域</div>
    </div>
    <!-- 所属领域 -->
    <div class="meeting-time fs28 fw400 fc666 dfr flex-warp" v-if="info.field">
      {{ info.field.name }}
    </div>

    <!-- 会议主题title -->
    <div class="host-box dfr cross-center">
      <div class="inator-img dfr">
        <img
          src="../assets/images/meeting-theme.png"
          alt=""
          class="w100 h100"
        />
      </div>
      <div class="fs32 fw500 fc333">会议主题</div>
    </div>
    <!-- 会议主题 -->
    <div class="answer-box" v-if="info.questions">
      <div class="ask dfr cross-center">
        <img src="../assets/images/shape.png" alt="" />
        <span class="fs28 fw500 fc333">我的困惑是，我如何</span>
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[0] }}
      </div>

      <div class="ask ask-reuse dfr cross-center">
        <img src="../assets/images/shape.png" alt="" />
        <span class="fs28 fw500 fc333">这个问题是重要的，因为</span>
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[1] }}
      </div>

      <div class="ask ask-reuse dfr cross-center">
        <img src="../assets/images/shape.png" alt="" />
        <span class="fs28 fw500 fc333"
          >到目前为止，为了解决这个问题我已经做了</span
        >
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[2] }}
      </div>

      <div class="ask ask-reuse dfr cross-center">
        <img src="../assets/images/shape.png" alt="" />
        <span class="fs28 fw500 fc333">我希望小组帮助我的是</span>
      </div>
      <div class="reply fs28 fw400 fc666">
        {{ info.questions[3] }}
      </div>
    </div>

    <!-- 图片 -->
    <div class="img-box dfr main-between flex-wrap">
      <div
        class="img-item"
        v-for="(item, index) in info.files"
        :key="index"
        @click="previewImg(index)"
      >
        <img
          v-if="item.url"
          :src="item.url | addImgFix"
          alt=""
          class="w100 h100 object-cover"
        />
        <img
          class="w100 h100 object-cover"
          v-else
          :src="item | addImgFix"
          alt=""
        />
      </div>
    </div>

    <!-- botton -->
    <div class="b-button-box bgfff">
      <div class="recruiting-button dfc flex-center">
        <icy-button
          @click.native="confirm"
          height="0.8rem"
          v-if="
            info.status != 5 &&
              info.meeting_user_count < info.need_directory_user_count
          "
          border-radius="0.44rem"
          class="fs28 fw500 fctheme"
        >
          确认参加此会议
        </icy-button>
        <icy-button
          height="0.8rem"
          v-if="
            info.status != 5 &&
              info.meeting_user_count >= info.need_directory_user_count
          "
          border-radius="0.44rem"
          class="fs28 fw500 fctheme op8"
        >
          参会人数已满
        </icy-button>
        <icy-button
          height="0.8rem"
          v-if="info.status == 5"
          border-radius="0.44rem"
          class="fs28 fw500 fctheme op8"
        >
          会议已结束
        </icy-button>
      </div>
    </div>

    <!-- 地图展示 -->
    <van-popup v-model="showMap" position="bottom" round>
      <div class="map-wrap">
        <baidu-map
          v-if="center"
          class="w100 h100"
          :center="center"
          :zoom="zoom"
          @ready="mapHandler"
        >
          <bm-marker
            :position="center"
            :dragging="true"
            @click="infoWindowOpen"
            animation="BMAP_ANIMATION_BOUNCE"
          >
            <bm-info-window
              :show="addressShow"
              @close="infoWindowClose"
              @open="infoWindowOpen"
              >{{ info.meeting_space.name }} <br />
              {{ info.meeting_space.address }}</bm-info-window
            >
          </bm-marker>
        </baidu-map>
        <empty v-else content="暂不支持自定义地址显示"></empty>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      id: "",
      userId: "",
      info: null,
      myId: "",
      myUserInfo: null,
      hostUserId: "",
      siDongList: [],
      isLogin: false,
      // 地图
      showMap: false,
      addressShow: true,
      center: null,
      zoom: 18
    };
  },
  async created() {
    this.$title("会议详情");
    // this.id = this.$route.query.id;
    this.isLogin = this.$localStore.get("isLogin") || false;
    this.id = parseInt(this.$route.query.meetingId) || "";
    this.userId = parseInt(this.$route.query.userId) || "";
    this.hostUserId = parseInt(this.$route.query.hostUserId) || "";
    // if (!this.isLogin) {
    //   this.$go("login", {
    //     back: 3,
    //     meetingId: meetingId,
    //     userId: userId,
    //     hostUserId: hostUserId
    //   });
    //   return;
    // }

    await this.initInfo();
    await this.initUser();
    if (!this.isLogin) {
      return;
    }
    setTimeout(async () => {
      if (
        this.myUserInfo &&
        this.myUserInfo.name &&
        this.myUserInfo.avatar &&
        this.myUserInfo.company &&
        this.myUserInfo.industry &&
        this.myUserInfo.scale &&
        this.myUserInfo.field &&
        this.myUserInfo.job
      ) {
        await this.getSiDongList();
        // type 0 我创建的  1 我主持的 2 我参与的

        let _type = 2;
        if (this.myUserInfo.id == this.info.host_user_id) {
          _type = 1;
        } else if (this.myUserInfo.id == this.info.user_id) {
          _type = 0;
        }
        if (
          this.myUserInfo.id == this.info.host_user_id ||
          this.myUserInfo.id == this.info.user_id
        ) {
          this.$goReplace("meetingDetail", {
            id: this.id,
            type: _type,
            invite: 1
          });
          return;
        }
        this.siDongList.forEach(item => {
          if (item.user_id == this.myId) {
            this.$goReplace("meetingDetail", {
              id: this.id,
              type: _type,
              invite: 1
            });
          }
        });
      } else {
        this.$go("perfect", {
          type: 0,
          backStatus: 3,
          meetingId: this.id,
          userId: this.userId
        });
      }
    }, 10);
  },
  methods: {
    previewImg(index) {
      let images = [];
      this.info.files.forEach(item => {
        images.push(this.$addImgFix(item));
      });
      ImagePreview({
        images: images,
        startPosition: index
      });
    },
    async initInfo() {
      const resp = await this.$API
        .get("shareMeetingDetail", { id: this.id }, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.info = resp.data;
        let lnglat = null;
        if (
          this.info &&
          this.info.meeting_space &&
          this.info.meeting_space.longitude &&
          this.info.meeting_space.latitude
        ) {
          lnglat = {
            lng: this.info.meeting_space.longitude,
            lat: this.info.meeting_space.latitude
          };
        }
        this.center = lnglat;
      }
    },
    async initUser() {
      if (!this.isLogin) {
        return;
      }
      const resp = await this.$API.get("userInfo", false).then(resp => resp);
      if (resp.code == 1000) {
        this.myId = resp.data.info.id;
        this.myUserInfo = resp.data.info;
      }
    },
    async getSiDongList() {
      if (!this.isLogin) {
        return;
      }
      const resp = await this.$API
        .get("joinMeetingSidongList", { id: this.id })
        .then(resp => resp);
      if (resp.code == 1000) {
        this.siDongList = resp.data.list;
      }
    },
    mapHandler() {},
    infoWindowClose() {
      this.addressShow = false;
    },
    infoWindowOpen() {
      this.addressShow = true;
    },
    // 确认参加
    async confirm() {
      if (!this.isLogin) {
        this.$go("login", {
          back: 3,
          meetingId: this.id,
          userId: this.userId,
          hostUserId: this.hostUserId
        });
        return;
      }
      let shareUserId = this.userId;
      if (this.hostUserId) {
        shareUserId = this.hostUserId;
      }
      const data = {
        meetingId: this.id,
        shareUserId: shareUserId
      };
      const resp = await this.$API
        .post("shareAddMeeting", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$goReplace("succeed", { id: this.id, type: 2 });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-button {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.invite {
  width: 7.5rem;
  .inator-box {
    margin-top: 0.36rem;
  }
  .inator-img {
    width: 0.32rem;
    height: 0.32rem;
    margin-left: 0.36rem;
    margin-right: 0.2rem;
  }
  .head-box {
    width: 6.78rem;
    margin: 0.2rem auto auto;
    background: #fbfbfb;
    border-radius: 0.12rem;
    .head {
      margin: 0.36rem 0 0.36rem 0.36rem;
      .portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
        }
        .badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
          }
        }
      }
      .message-box {
        margin-left: 0.2rem;
        .my-job {
          margin-left: 0.12rem;
        }
        .company {
          margin-top: 0.08rem;
          .company-number {
            margin-left: 0.08rem;
          }
        }
        .scale {
          margin-top: 0.04rem;
        }
      }
    }
    .field-list-box {
      margin: 0 0 0.36rem 0.36rem;
      .field-list {
        background: #f6f6f6;
        border-radius: 0.08rem;
        padding: 0.08rem 0.16rem;
        margin-right: 0.12rem;
        margin-top: 0.15rem;
      }
    }
  }
  .host-box {
    margin-top: 0.6rem;
  }
  .meeting-time {
    margin: 0.2rem 0 0 0.88rem;
  }
  .answer-box {
    margin-top: 0.36rem;
    margin-left: 0.88rem;
    .ask {
      img {
        width: 0.12rem;
        height: 0.12rem;
        margin-right: 0.16rem;
      }
    }
    .ask-reuse {
      margin-top: 0.48rem;
    }
    .reply {
      margin-top: 0.16rem;
      margin-left: 0.28rem;
    }
  }
  .img-box {
    width: 6.26rem;
    margin: 0.6rem 0.36rem 0.6rem 0.88rem;
    .img-item {
      width: 3.02rem;
      height: 3.02rem;
      overflow: hidden;
      border-radius: 0.08rem;
    }
    .img-item:nth-child(n + 3) {
      margin-top: 0.22rem;
    }
  }
  .confirm-bot {
    margin-bottom: 0.2rem;
  }
}
.map-wrap {
  height: 90vh;
  width: 100vw;
}
</style>
